<template>
  <div class="page-wrapper">
    <h1>Report</h1>
    <v-dialog v-model="showDialog" persistent width="66%">
      <v-card style="display: flex; flex-direction: column">
        <v-card-title class="headline">
          Report<v-spacer></v-spacer>
          <Button
            id="saveDialog"
            :clickAction="downloadReportClicked"
            width="160"
            :text="$t('Download Report')"
            class="ma-2"
          />
        </v-card-title>
        <v-divider></v-divider>
        <iframe
          seamless
          id="snapshot"
          src="about:blank"
          style="
            border: none;
            overflow: auto;
            margin: 0;
            padding: 10px 10px 10px 0;
            flex-grow: 1;
            height: 60vh;
          "
        ></iframe>
      </v-card>
    </v-dialog>
    <!-- <div  v-html="reportHtml" class="report-wrapper"></div> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { selectedHost } from '@/config'
import { DownloadReport } from '@/components/WebsiteInterface/Websiteinterface'
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'Report',
  data() {
    return {
      reportHtml: '',
      showDialog: true,
    }
  },
  components: {
    Button,
  },
  methods: {
    downloadReportClicked() {
      const reportObj = {
        summary: this.reportHtml,
        date: this.formatDateTime(),
      }
      DownloadReport(reportObj)
    },
    formatDateTime() {
      const now = new Date()

      const day = String(now.getDate()).padStart(2, '0')
      const month = String(now.getMonth() + 1).padStart(2, '0') // January is 0!
      const year = now.getFullYear()

      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      const seconds = String(now.getSeconds()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
  },
  async mounted() {
    const { ids, key } = this.$route.query
    console.log(ids)
    const idsArr = ids.split(';').map(Number)
    console.log(idsArr)
    const response = await fetch(
      `${selectedHost}/Engine/${key}/HistoryJs/snapshot?status=0`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        body: JSON.stringify(idsArr),
      }
    )

    console.log(response)
    this.reportHtml = await response.text()
    console.log('hi')
    console.log(document.getElementById('snapshot').src)
    document.getElementById('saveDialog').href = document.querySelector(
      '#snapshot'
    ).src = URL.createObjectURL(
      new Blob([this.reportHtml], { type: 'text/html' })
    )
    this.showDialog = true
  },
}
</script>

<style scoped>
.report-wrapper {
  width: fit-content;
  background-color: white;
  direction: ltr;
  max-height: 90%;
}

.page-wrapper {
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
  justify-content: center;
  align-items: center;
}

ul {
  max-height: 500px !important;
  overflow: auto !important;
}
</style>
