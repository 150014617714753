<template>
  <div class="add-edit-wrapper">
    <v-card class="card" style="width: 400px">
      <!--TITLE-->
      <v-card-title class="text-center bg-grey fs-1-5r card-title">
        {{ type === 'add' ? $t('Add Guest') : $t('Edit Guest') }}
      </v-card-title>

      <v-card-text class="my-2">
        <v-text-field
          v-model="casualUserAddEdit.email"
          :label="$t('Email')"
          outlined
          dense
          :class="[type === 'add' && 'required']"
        ></v-text-field>

        <v-text-field
          v-model="casualUserAddEdit.name"
          :label="$t('Full Name')"
          outlined
          dense
          :class="[type === 'add' && 'required']"
        ></v-text-field>

        <div class="wrapper-2 py-2">
          <PhoneNumberInput
            width="100%"
            :parentObject="casualUserAddEdit"
            class="mx-1"
            :actionType="loggedUser.isCasualsTfaNeeded ? 'add' : 'edit'"
            :isHideDropdown="false"
            :isHideUnderline="true"
            :required="computedRequired"
          />
        </div>

        <div class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="type === 'edit'"
                class="mt-4"
                v-on="on"
                @click="deleteCasualClicked"
                color="red"
                >mdi-delete</v-icon
              >
            </template>
            <span>
              {{ $t('Delete Guest') }}
            </span>
          </v-tooltip>
        </div>
      </v-card-text>

      <v-card-actions>
        <Button
          :text="$t('Cancel')"
          :clickAction="closeDialog"
          btnType="grey"
          class="mx-1"
        />

        <div @mouseover="showMessage = true" @mouseleave="showMessage = false">
          <Button
            class="mx-1"
            :text="$t('Save')"
            :clickAction="saveCasualUserPopUp"
            :disabled="!!errMsg"
          />
        </div>

        <transition name="slide-fade">
          <span class="mr-8 red--text message fs-1r" v-if="showMessage">{{
            errMsg
          }}</span>
        </transition>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'
import { validateEmail } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      type: '',
      errMsg: null,
      showMessage: false,
    }
  },
  props: {
    closeDialog: Function,
    casualUserAddEdit: Object,
    saveCasualUserPopUp: Function,
    deleteCasualClicked: Function,
  },

  watch: {
    casualUserAddEdit: {
      handler() {
        this.checkSettings()
      },
      deep: true,
      immediate: true,
    },
  },
  components: { Button, PhoneNumberInput },

  computed: {
    ...mapGetters(['loggedUser']),
    computedRequired() {
      return this.loggedUser.isCasualsTfaNeeded
    }
  },
  methods: {
    checkSettings() {
      if (!this.casualUserAddEdit.email)
        return (this.errMsg = this.$t('Email is required'))

      if (!validateEmail(this.casualUserAddEdit.email))
        return (this.errMsg = this.$t('Email is not valid'))

      if (!this.casualUserAddEdit.name)
        return (this.errMsg = this.$t('Full name is required'))

      if (this.loggedUser.isCasualsTfaNeeded) {
        if (
          !this.casualUserAddEdit.phoneNumber ||
          this.casualUserAddEdit.phoneNumber.length < 10
        )
          return (this.errMsg = this.$t('Mobile is required'))
      }

      this.errMsg = null
    },
  },

  created() {
    if (this.casualUserAddEdit.email) this.type = 'edit'
    else this.type = 'add'
  },
}
</script>

<style scoped lang="scss">
.add-edit-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
