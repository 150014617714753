<template>
  <div class="d-flex align-center px-3 dynamic-field-wrapper ">
    <v-text-field
      v-model="interfaceDynamicField.textToDisplay"
      label="Label"
      height="18px"
      class="mt-2 mx-3 pa-0"
      maxlength="50"
      hide-details
    ></v-text-field>
    <v-select
      v-if="loggedUser"
      :items="enumItems"
      v-model="interfaceDynamicField.validation"
      :label="$t('Validation')"
      item-text="text"
      item-value="value"
      class="w-145"
    ></v-select>
    <div>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-hover v-slot="{ hover }">
            <v-icon
              :color="hover ? 'red' : 'grey'"
              v-on="on"
              class="my-2"
              @click.stop="deleteFieldClicked"
              >mdi-delete</v-icon
            >
          </v-hover>
        </template>
        <span>Delete field</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ManageDynamicFields',

  
  watch: {
    interfaceDynamicField: function (n) {
      if (!n.textToDisplay) {
        this.$emit('set-error-messasge', 'All fields must have label')
      }
    },
  },
  emits: [
    'delete-field',
    'set-error-messasge',
  ],
  props: {
    interfaceDynamicField: Object,
    fieldIndex: Number,
  },
  computed: {
    ...mapGetters(['isDarkMode', 'loggedUser']),
    nthField() {
      switch (this.fieldIndex) {
        case 0:
          return 'First'
        case 1:
          return 'Second'
        case 2:
          return 'Third'
      }
    },
    
    enumItems() {
        console.log(this.interfaceDynamicField.validation)
      return [
        { text: this.$t('None'), value: 0 },
        {
          text: this.$t('Email'),
          value: 1,
          disabled: this.loggedUser.interfaceDynamicFieldsForRegistered.some(
            el => el.validation === 1
          ) && this.interfaceDynamicField.validation != 1,
        },
        { text: this.$t('Phone Number'), value: 2 },
        { text: this.$t('Israeli ID'), value: 3 },
      ]
    },
  },
  methods: {
    deleteFieldClicked() {
      this.$emit('delete-field')
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}

.advanced-settings-target {
  transition: box-shadow 0.25s ease;
  box-shadow: #a1a1a1 0px 1px;
  &:hover {
    box-shadow: #000 0px 1px;
  }
}

.advanced-settings-target-active {
  transition: box-shadow 0.25s ease;
  box-shadow: #2289dd 0px 2px;
  &:hover {
    box-shadow: #2289dd 0px 2px;
  }
}

.advanced-settings-target-active-error {
  transition: box-shadow 0.25s ease;
  box-shadow: #f44336 0px 2px;
  &:hover {
    box-shadow: #000 0px 1px;
  }
}

.darkmode-advanced-settings-target {
  &:hover {
    box-shadow: #fff 0px 1px;
  }
}

.advanced-title {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease;
  &:active {
    color: rgba(0, 0, 0, 1);
  }
}

.inputs-wrapper {
  position: absolute;
  background: #fff;
  z-index: 10;
  width: 445px;
  top: 30px;
  transition: all 0.3s ease;
  overflow: auto;
}

.dynamic-field-wrapper {
  min-height: 40px;
}
</style>
