<template>
  <div>
    <div
      class="pos-relative"
      v-for="(item, i) of session.interfaceDynamicFields.filter(
        el => el.textToDisplay
      )"
    >
      <v-text-field
        v-model="session.interfaceDynamicFields[i].value"
        @input="inputChanged"
        :error-messages="errorMessages[i]"
        :disabled="isInMiddleOfFiltering"
        :label="session.interfaceDynamicFields[i].textToDisplay"
        @blur="inputBlured(i)"
        outlined
        dense
        maxlength="40"
        counter
      >
        <template
          v-slot:append
          v-if="session.interfaceDynamicFields[i].isHierarchy"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-information-outline</v-icon>
            </template>
            <span v-html="forbiddenChars + ' ' + $t('are forbidden')"> </span>
          </v-tooltip>
        </template>

        <template v-slot:counter="{ props }">
          <span :class="[props.value >= 35 && 'red--text']">
            {{ ` ${props.value} / ${props.max}` }}</span
          >
        </template>
      </v-text-field>
      <div :class="[language === 'he' ? 'absolute-btn-he' : 'absolute-btn-en']">
        <Button
          v-if="isShowSendCodeBtn(i)"
          :clickAction="() => sendVerificationClicked(i)"
          text="Send Verification Code"
          class=""
        />
        <div
          v-else-if="isShowVerifiedSpan(i)"
          class="d-flex"
          style="margin-top: 3px"
        >
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <span class="verified">Verified</span>
        </div>
      </div>
    </div>
    <EnterCodeDialog
      v-if="isShowEnterCodeDialog"
      :closeDialog="() => (isShowEnterCodeDialog = false)"
      :verifyCode="code => verifyCode(code, currentCodeDialogIndex)"
      :email="session.interfaceDynamicFields[currentCodeDialogIndex].value"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  validateEmail,
  validateIsraelId,
  validatePhoneNumber,
  isFolderNameValid,
} from '@/utils'
import { api } from '@/config'
import Button from '@/components/BaseComponents/Button/Button.vue'
import EnterCodeDialog from './children/EnterCodeDialog.vue'
import Language from '@/components/PageParts/Header/Childrens/Language/Language.vue'

export default {
  name: 'DynamicFields',

  data() {
    return {
      isSubjectError: false,
      forbiddenChars: '> , < , : , " , | , ? , *',
      errorMessages: [],
      isEmailVerified: [],
      guid: '',
      isShowEnterCodeDialog: false,
      verifiedEmailsList: [],
      bluredFields: [],
      currentCodeDialogIndex: null,
    }
  },

  props: {
    session: Object,
    lightSessionRestart: Function,
    // isAuthenticateEmail: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  components: { Button, EnterCodeDialog },
  computed: {
    ...mapGetters(['loggedUser', 'isInMiddleOfFiltering', 'language']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'setSessionField']),
    inputBlured(i) {
      this.bluredFields[i] = true
      this.inputChanged()
    },
    inputChanged() {
      if (!this.session.dummy) this.$set(this.session, 'dummy', true)
      else this.$set(this.session, 'dummy', false)
      for (let i = 0; i < this.session.interfaceDynamicFields.length; i++) {
        if (this.bluredFields[i] === false) continue
        //handle email verification:
        if (
          this.session.interfaceDynamicFields[i].validation === 1 &&
          this.session.interfaceDynamicFields[i].isVerifyEmail
        ) {
          // case email not verified yet, but status of isEmailVerified is true:
          if (
            !this.verifiedEmailsList.includes(
              this.session.interfaceDynamicFields[i].value
            ) &&
            this.isEmailVerified[i]
          ) {
            this.isEmailVerified[i] = false
            this.setSessionField({
              field: 'isEmailVerified',
              value: false,
            })
          }
          // case email is already verified, but status of isEmailVerified is false:
          if (
            this.verifiedEmailsList.includes(
              this.session.interfaceDynamicFields[i].value
            ) &&
            !this.isEmailVerified[i]
          ) {
            this.isEmailVerified[i] = true
            this.setSessionField({
              field: 'isEmailVerified',
              value: true,
            })
          }
        }
        if (
          this.session.interfaceDynamicFields[i].required &&
          !this.session.interfaceDynamicFields[i].value
        ) {
          this.$set(
            this.errorMessages,
            i,
            `${this.session.interfaceDynamicFields[i].textToDisplay} ${this.$t(
              'is required'
            )}`
          )
          continue
        } else if (
          this.session.interfaceDynamicFields[i].isHierarchy &&
          this.session.interfaceDynamicFields[i].value &&
          !isFolderNameValid(this.session.interfaceDynamicFields[i].value)
        ) {
          this.$set(
            this.errorMessages,
            i,
            this.forbiddenChars + ' ' + this.$t('are forbidden')
          )
          continue
        } else if (this.session.interfaceDynamicFields[i].validation) {
          const v = this.session.interfaceDynamicFields[i].value
          switch (this.session.interfaceDynamicFields[i].validation) {
            case 1: // email
              if (v && !validateEmail(v.trim())) {
                this.$set(this.errorMessages, i, 'Email is not valid')
                return
              }
              break
            case 2: // phone number
              if (v && !validatePhoneNumber(v.trim())) {
                this.$set(this.errorMessages, i, 'Mobile is not validt')
                return
              }
              break
            case 3: // israel id
              if (v && !validateIsraelId(v.trim())) {
                this.$set(this.errorMessages, i, 'Invalid ID')
                return
              }
              break
          }
        }
        this.$set(this.errorMessages, i, '')
      }
      // if (false) {
      //   this.isEmailVerified = true
      //   this.setSessionField({
      //     field: 'isEmailVerified',
      //     value: true,
      //   })
      // }
      // this.lightSessionRestart()
    },
    isShowSendCodeBtn(i) {
      const isShow =
        validateEmail(this.session.interfaceDynamicFields[i].value?.trim()) &&
        this.session.interfaceDynamicFields[i].isVerifyEmail &&
        !this.isEmailVerified[i]
      return isShow
    },
    isShowVerifiedSpan(i) {
      if (
        !validateEmail(this.session.interfaceDynamicFields[i].value?.trim())
      ) {
        this.isEmailVerified[i] = false
        return false
      }
      return this.isEmailVerified[i]
    },
    async sendVerificationClicked(i) {
      try {
        console.log(i)
        this.SET_PROCESSING(true)
        const res = await api.get(
          `casuals/verify-email-send/${this.loggedUser.organizationId}/${this.session.interfaceDynamicFields[i].value}`
        )
        if (res.status === 200) {
          this.guid = res.data.guid
          this.currentCodeDialogIndex = i
          this.isShowEnterCodeDialog = true
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    async verifyCode(code, i) {
      try {
        this.SET_PROCESSING(true)
        const res = await api.get(
          `casuals/verify-email-check/${this.guid}/${code}`
        )
        if (res.status === 200) {
          this.isEmailVerified[i] = true
          this.setSessionField({
            field: 'isEmailVerified',
            value: true,
          })
          this.verifiedEmailsList.push(
            this.session.interfaceDynamicFields[i].value
          )
          this.currentCodeDialogIndex = null
          this.isShowEnterCodeDialog = false
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
  mounted() {
    for (let i = 0; i < this.session.interfaceDynamicFields.length; i++) {
      // this.isEmailVerified.push(
      //   this.session.interfaceDynamicFields[i].isVerifyEmail ? false : true
      // )
      this.bluredFields.push(false)
    }
  },
}
</script>
<style scoped>
.verified {
  color: #4bb71b;
  font-size: large;
  font-weight: 500;
  transition: 2s;
}
.success-animation {
  margin: 150px auto;
}

.checkmark {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
  stroke-width: 7;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  margin-right: 4px;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 7;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

.absolute-btn-en {
  position: absolute;
  top: 5px;
  right: 50px;
}
.absolute-btn-he {
  position: absolute;
  top: 5px;
  right: 293px;
}
</style>
