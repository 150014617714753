<template>
  <div
    id="registered-users"
    class="registered-users-wrapper"
    :class="[type !== 'mobile' && 'pos-relative', 'recipients-wrapper']"
  >
    <v-autocomplete
      v-model="session.selectedUsers"
      :items="computedUsersList"
      :disabled="isInMiddleOfFiltering"
      :label="$t('Registered Recipients')"
      item-value="userEmail"
      item-text="userEmail"
      multiple
      outlined
      dense
      chips
      small-chips
      hide-details
      :filter="customFilter"
      :search-input.sync="search"
    >
      <!--MAKE THE SELECTION -->

      <template v-slot:selection="{ item }" >
        <span class="pill">{{ item.userName || item.userEmail }}</span>
      </template>

      <!--TO SELECT ALL-->
      <v-list-item
        slot="prepend-item"
        class="list-item-wrapper select-all"
        v-if="isShowSelectAll"
      >
        <v-checkbox
          v-model="isSelectAll"
          hide-details
          class="ma-0 pa-0"
          color="primary"
          :label="$t('Select all')"
        >
        </v-checkbox>
      </v-list-item>
      <template
        #item="{
          item,
          on,
          attrs: { disabled, inputValue, ripple, internalValue },
        }"
      >
        <v-list-item v-on="on" class="list-item-wrapper">
          <v-checkbox
            v-on="$listeners"
            :disabled="disabled"
            :input-value="isItemSelected(item)"
            hide-details
            :ripple="ripple"
          >
            <template v-slot:label>
              <div class="label-wrapper">
                <v-list-item-title>{{ item.userEmail }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.userName }}
                </v-list-item-subtitle>
              </div>
            </template>
          </v-checkbox>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RegisteredRecipients',
  data() {
    return {
      isSelectAll: false,

      search: '',
    }
  },
  props: {
    session: Object,
    errors: Object,
    lightSessionRestart: Function,
    type: String,
  },

  watch: {
    isSelectAll: {
      handler: function (n) {
        if (n){
          this.session.selectedUsers = this.computedUsersList.map(el => el.userEmail)
        } 
        else this.session.selectedUsers = []
      },
    },
    'session.selectedRoute': {
      handler: function (n) {
        if (!n) return
        const activeUsers = []
        //get all the targets that are with status 0
        //and if there is only one then put it on selected registered users
        for (const user of n.targetEndPoints) {
          //get all
          if (user.userStatus === 0) activeUsers.push(user)
          if (activeUsers.length > 1) break
        }
        if (activeUsers.length === 1 && !this.session.selectedUsers.length)
          this.session.selectedUsers.push(activeUsers[0].userEmail)
      },
      deep: true,
      immediate: true,
    },
    
  },
  computed: {
    ...mapGetters(['loggedUser', 'isInMiddleOfFiltering']),
    computedUsersList() {
      let users = []
      //if this is regular user and there is no selected route
      if (!this.session.selectedRoute) return []
      //regular users
      else {
        //this function will check if all the emails provided to select all are uniqe and the users arent blocked or removed
        for (let targetEndPoint of this.session.selectedRoute.targetEndPoints) {
          if (
            !users.includes(targetEndPoint.userEmail) &&
            targetEndPoint.userStatus === 0
          ) {
            users.push({
              userEmail: targetEndPoint.userEmail,
              userName: targetEndPoint.userName,
            })
          }
        }
      }
      this.selectUserList = users
      return users.sort((a, b) => {
        if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) return -1
        if (a.userEmail.toLowerCase() > b.userEmail.toLowerCase()) return 1
        return 0
      })
    },
    isShowSelectAll() {
      return this.computedUsersList?.length > 1
    },
    //FOR SELECT SOME AND ALL
    isAllUsersSelected() {
      return this.session.selectedUsers.length === this.selectUserList.length
    },
    someUsersSelected() {
      return this.session.selectedUsers.length > 0 && !this.isAllUsersSelected
    },
    icon() {
      if (this.isAllUsersSelected) return 'mdi-close-box'
      if (this.someUsersSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    selectAllOrSomeUserClicked() {
      this.$nextTick(() => {
        if (this.isAllUsersSelected) {
          this.session.selectedUsers = []
        } else {
          this.session.selectedUsers = this.selectUserList.map(
            el => el.userEmail
          )
        }
      })
    },
    customFilter(item, queryText) {
      const email = item.email.toLowerCase()
      const name = item.name.toLowerCase()
      const query = queryText.toLowerCase()
      return email.includes(query) || name.includes(query)
    },
    isItemSelected(item) {
      return this.session.selectedUsers.some(el => el === item.userEmail)
    },
  },

  // created() {
  //   if (
  //     this.loggedUser.role === 'Casual' &&
  //     this.loggedUser.boundedUsers.length === 1
  //   ) {
  //     this.session.selectedUsers = [this.loggedUser.boundedUsers[0].email]
  //   }
  // },
}
</script>

<style lang="scss">
.registered-users-wrapper {
  margin-bottom: 26px;
  .pill {
    margin-top: 5px !important;
    background-color: #e3e3e3;
    padding: 2px 5px;
    border-radius: 10px;
    margin: 2px 4px;
  }
}
</style>

<style scoped>
.email-pill {
  background-color: #e3e3e3;
  padding: 2px 5px;
  border-radius: 10px;
  margin: 2px 4px;
}

.list-item-wrapper {
  height: fit-content !important;
  cursor: pointer;
}
.list-item-wrapper:hover {
  background-color: #f6f6f6;
}

.list-item-wrapper >>> .v-input {
  width: 100%;
}
.list-item-wrapper >>> .v-input--selection-controls {
  margin: 6px !important;
}

.list-item-wrapper >>> .mdi-checkbox-marked {
  color: rgb(34, 137, 221) !important;
  caret-color: rgb(34, 137, 221) !important;
}

.select-all {
  border-bottom: 1px solid rgb(236, 234, 234);
}

.list-item-wrapper
  >>> .v-input--selection-controls
  >>> .v-input__control
  >>> .v-message {
  background-color: red !important;
}
</style>
