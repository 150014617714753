<template>
  <!--UPLOADING DETAILS-->
  <div
    tabindex="0"
    class="wrapper-1 bg-white w-420 pos-relative"
    :class="
      isShowUploadingDetails ? 'upload-details-open' : 'upload-details-close'
    "
  >
    <!--TITLE-->
    <div
      v-if="isInMiddleOfFiltering"
      class="d-flex bg-grey pa-3"
      :class="[!isShowUploadingDetails ? 'wrapper-1 mb-2' : 'title-border']"
    >
      <v-icon
        @click="isShowUploadingDetails = !isShowUploadingDetails"
        class="pointer"
        large
        :aria-label="`${isShowUploadingDetails ? 'open' : 'close'} Settings`"
        >mdi-chevron-{{ isShowUploadingDetails ? 'down' : 'right' }}</v-icon
      >
      <h3 class="font-weight-medium fs-1-5r">{{ $t('Details') }}&nbsp;</h3>
    </div>

    <!--DIV ONLY FOR OVERFLOW -->
    <div
      :class="[
        $vuetify.breakpoint.height < 729 && 'overflow-small-screen-height',
      ]"
    >
      <!--CONTENT OF THE UPLOADING FIELD-->
      <v-tabs
        v-model="activeTab.tab"
        v-show="isShowUploadingDetails"
        class="px-2 pb-2 uploading-details-wrapper"
      >
        <div v-if="isShowTabs" class="mx-auto d-flex">
          <v-tab href="#regular">{{ $t('Registered') }}</v-tab>
          <v-tab href="#casual">{{ $t('Guest') }}</v-tab>
        </div>

        <v-tab-item value="regular" class="mt-2">
          <RegularSend
            :session="session"
            :userActiveSourceRoutes="userActiveSourceRoutes"
            :restartSessionClicked="restartSessionClicked"
            :lightSessionRestart="lightSessionRestart"
          />
        </v-tab-item>

        <v-tab-item value="casual" class="mt-2">
          <CasualSend :session="session" />
        </v-tab-item>
      </v-tabs>

      <!--MESSAGE WHY DOWNLOAD BUTTON IS DISABLED-->
      <div class="red--text d-flex align-center" :class="computedHeight">
        <Transition name="slide-fade">
          <span
            v-if="isShowFilteringErrMessage"
            class="mx-auto fs-1-25r text-center"
          >
            {{ filteringErrMessage }}
          </span>
        </Transition>
      </div>

      <!--START-RESTART BUTTONS-->
      <StartRestartButtons
        :session="session"
        :filteringErrMessage="filteringErrMessage"
        :setIsShowFilteringErrMessage="v => (isShowFilteringErrMessage = v)"
        :startFilteringClicked="startFilteringClicked"
        :restartSessionClicked="restartSessionClicked"
        :setIsShowUploadingDetails="v => (isShowUploadingDetails = v)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Children
import StartRestartButtons from './Children/StartRestartButtons/StartRestartButtons.vue'
import CasualSend from '@/components/WebsiteInterface/CommonComponents/CasualSend/CasualSend.vue'
import RegularSend from '@/components/WebsiteInterface/CommonComponents/RegularSend.vue'

export default {
  name: 'UploadingDetails',
  data() {
    return {
      isShowFilteringErrMessage: false,
      isShowUploadingDetails: true,
    }
  },
  props: {
    session: Object,
    userActiveSourceRoutes: Array,
    filteringErrMessage: String,
    startFilteringClicked: Function,
    restartSessionClicked: Function,
    lightSessionRestart: Function,
    activeTab: Object,
  },
  watch: {
    isInMiddleOfFiltering(n) {
      if (n === true) this.isShowUploadingDetails = false
      else if (n === false) {
        this.isShowFilteringErrMessage = false
        this.isShowUploadingDetails = true
      }
    },
    'session.selectedRoute': {
      handler: function (n) {
        if (!n && this.session) this.session.interfaceDynamicFields = undefined
        else if (n)
          this.session.interfaceDynamicFields = JSON.parse(
            JSON.stringify(n.interfaceDynamicFields)
          )
      },
    },
    'activeTab.tab': {
      handler: function (n) {
        if (n === 'casual') {
          console.log(this.loggedUser)
          this.session.interfaceDynamicFields = JSON.parse(
            JSON.stringify(this.loggedUser.interfaceDynamicFieldsForRegistered)
          )
        }
        if (n === 'regular') {
          if (
            this.loggedUser.role != 'Individual' &&
            !this.userActiveSourceRoutes.length
          )
            this.activeTab.tab = 'casual'
          else this.session.interfaceDynamicFields = []
        }
      },
    },
  },
  components: { StartRestartButtons, RegularSend, CasualSend },

  computed: {
    ...mapGetters(['isInMiddleOfFiltering', 'accessibility', 'loggedUser']),

    isShowTabs() {
      const isShowCasuals = this.loggedUser.isAllowCasuals
      const isShowRegistered = this.userActiveSourceRoutes.length > 0
      if (!isShowCasuals || !isShowRegistered) {
        const el = document.getElementsByClassName('v-slide-group')
        if (el && el.length && el[1]) el[1].style.display = 'none'
        return false
      } else {
        const el = document.getElementsByClassName('v-slide-group')
        if (el && el.length && el[1]) el[1].style.display = ''
      }

      return true
    },
    computedHeight() {
      if (this.accessibility.fontSize === 1) return 'h-30'
      else if (this.accessibility.fontSize === 1) return 'h-50'
      else return 'h-80'
    },
  },
}
</script>

<style scoped src="./UploadingDetails.css"></style>

<style scoped>
.v-tabs >>> .v-window {
  /* min-height: 300px !important; */
  overflow: visible;
  /* overflow-y: auto !important */
}
</style>
